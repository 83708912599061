<template lang="pug">
#root
  md-app(:md-scrollbar="false").app
    md-app-toolbar.flex.bg-white.md-toolbar
      md-button.md-icon-button.mobile(@click="menuVisible = !menuVisible")
        md-icon menu
      img.header-logo(src="@/assets/images/logo-36Kr.png")
      md-tabs.tabs(md-sync-route)
        md-tab(md-label="游戏开发者峰会", to="/", exact)
        md-tab(md-label="手游出海破新界", to="/campaign/tg2021")
        md-tab(md-label="最新产品与趋势", to="/campaign/gml")
        md-tab(md-label="成就全球强品牌", to="/campaign/competitive_brand")
        md-tab(md-label="跨境电商新篇章", to="/campaign/think_of_retail")
      .spacer
      md-button.md-dense.md-primary.md-outlined(href="#analysis-section")
        | 自我诊断分析
      md-button.md-dense.md-raised.md-primary.padding-btn.pc(href="/static/2021 中国跨境电商发展报告.pdf")
        | 下载《2021 中国跨境电商发展报告》
      md-button.md-dense.md-raised.md-primary.padding-btn.mobile(href="/static/2021 中国跨境电商发展报告.pdf")
        | 下载报告
    md-app-drawer(:md-active.sync="menuVisible")
      md-toolbar.md-transparent(md-elevation="0")
        span.md-title
          | 导航
      md-list
        md-list-item(to="/", exact)
          md-icon videogame_asset
          span.md-list-item-text
            | 游戏开发者峰会
        md-list-item(to="/campaign/tg2021")
          md-icon games
          span.md-list-item-text
            | 手游出海破新界
        md-list-item(to="/campaign/gml")
          md-icon trending_up
          span.md-list-item-text
            | 最新产品与趋势
        md-list-item(to="/campaign/competitive_brand")
          md-icon public
          span.md-list-item-text
            | 成就全球强品牌
        md-list-item(to="/campaign/think_of_retail")
          md-icon shopping_cart
          span.md-list-item-text
            | 跨境电商新篇章
    md-app-content.app-container
      .container.cover-container
        img.cover(src="@/assets/images/ThinkRetail2021_KV_210308_FA.svg")
        img.cover-mobile(src="@/assets/images/cover-mobile.png")
        p.cover-text
          | Think Retail 旨在与跨境电商伙伴们共同探究在疫情前后线上消费行为的变化，并提供海外赛道分析、中国跨境电商出海现状，及最佳实践案例分享。助力企业从流量到用户思维，实现品牌化转型，开启跨境电商的新篇章。
      .videos-container.bg-grey(ref="videos")
        .videos
          a(href="/videos/1", target="_blank")
            md-card.video-card
              md-card-media
                img(src="~@/assets/images/video_covers/1.png")
              md-card-content
                .video-content-text
                  | 视频
                h3
                  | 跨境电商新篇章：与 SHEIN、Anker 分享可持续发展
                a.span-btn(href="/videos/1", target="_blank")
                  md-icon.span-icon.md-primary play_arrow
                  span.span-text
                    | 播放
          a(href="/videos/2", target="_blank")
            md-card.video-card
              md-card-media
                img(src="~@/assets/images/video_covers/2.png")
              md-card-content
                .video-content-text
                  | 视频
                h3
                  | 消费者行为变化：后疫情时代的趋势及品类增长
                a.span-btn(href="/videos/2", target="_blank")
                  md-icon.span-icon.md-primary play_arrow
                  span.span-text
                    | 播放
          a(href="/videos/3", target="_blank")
            md-card.video-card
              md-card-media
                img(src="~@/assets/images/video_covers/3.png")
              md-card-content
                .video-content-text
                  | 视频
                h3
                  | 《跨境电商发展报告》：电商增长潜力及发展核心
                a.span-btn(href="/videos/3", target="_blank")
                  md-icon.span-icon.md-primary play_arrow
                  span.span-text
                    | 播放
          a(href="/videos/4", target="_blank")
            md-card.video-card
              md-card-media
                img(src="~@/assets/images/video_covers/4.png")
              md-card-content
                .video-content-text
                  | 视频
                h3
                  | AMII 的成长路：始于用户和产品打造品牌
                a.span-btn(href="/videos/4", target="_blank")
                  md-icon.span-icon.md-primary play_arrow
                  span.span-text
                    | 播放
          a(href="/videos/5", target="_blank")
            md-card.video-card
              md-card-media
                img(src="~@/assets/images/video_covers/5.png")
              md-card-content
                .video-content-text
                  | 视频
                h3
                  | NEIWAI内外的方法论：塑造和延续品牌故事
                  a.span-btn(href="/videos/5", target="_blank")
                    md-icon.span-icon.md-primary play_arrow
                    span.span-text
                      | 播放
      .arrow-container.bg-grey
        .arrow.arrow-back(@click="videosBack")
          div.arrow-icon(:class="{active: !isVideoToEnd}")
        .arrow.arrow-forward(@click="videosForward")
          div.arrow-icon(:class="{active: isVideoToEnd}")
      .container
        carousel(:perPage="1", :autoplay="false", :loop="true")
          slide
            .slide-container
              .article-card(@click="openUri('https://mp.weixin.qq.com/s/1StY2QOTUqOUG375jFz8aw')")
                img.article-image(src="@/assets/images/posts/post_10.png")
                .article-content
                  p.article-content-title
                    | 文章
                  h3
                    | 品牌站如何快速实现流量和销量双涨
              .article-card(@click="openUri('https://mp.weixin.qq.com/s/RAcjvyNomnJFDnYEXtxGcg')")
                img.article-image(src="@/assets/images/posts/post_9.jpg")
                .article-content
                  p.article-content-title
                    | 文章
                  h3
                    | 如何建设跨境电商品牌？
              .article-card(@click="openUri('https://mp.weixin.qq.com/s/SF9j0FavSwSmBVu_um8B6w')")
                img.article-image(src="@/assets/images/posts/post_8.jpg")
                .article-content
                  p.article-content-title
                    | 文章
                  h3
                    | 迈向新常态，跨境电商如何转型？
          slide
            .slide-container
              .article-card(@click="openUri('https://mp.weixin.qq.com/s/YH7EGc84SMjGHSTpizTrwA')")
                img.article-image(src="@/assets/images/posts/post_1.png")
                .article-content
                  p.article-content-title
                    | 文章
                  h3
                    | 转型电商，为什么这家焊接机出海企业利润翻了 5 倍？
              .article-card(@click="openUri('https://mp.weixin.qq.com/s/ZXjIYz88arWGgo33dad9OA')")
                img.article-image(src="@/assets/images/posts/post_2.png")
                .article-content
                  p.article-content-title
                    | 文章
                  h3
                    | 2021 出海乘风而上看这里，东南亚电商报告火热出炉
              .article-card(@click="openUri('https://mp.weixin.qq.com/s/QaT7Qias9vNZz6jmNFm-Jg')")
                img.article-image(src="@/assets/images/posts/post_3.png")
                .article-content
                  p.article-content-title
                    | 文章
                  h3
                    | 谷歌“热搜”报告带你直击出海新机遇
          slide
            .slide-container
              .article-card(@click="openUri('https://mp.weixin.qq.com/s/7DeGD76TMMLguTAPHza23Q')")
                img.article-image(src="@/assets/images/posts/post_4.png")
                .article-content
                  p.article-content-title
                    | 文章
                  h3
                    | 2021 出海方向新趋势？点击查看全方位市场洞察
              .article-card(@click="openUri('https://mp.weixin.qq.com/s/IoKUKVrmVIL6jj2DEgSqcg')")
                img.article-image(src="@/assets/images/posts/post_5.png")
                .article-content
                  p.article-content-title
                    | 文章
                  h3
                    | 明星商家恢复购物广告的神仙操作，这次全都告诉你
              .article-card(@click="openUri('https://mp.weixin.qq.com/s/uiWFWEMknC26EAZClpTFrg')")
                img.article-image(src="@/assets/images/posts/post_6.png")
                .article-content
                  p.article-content-title
                    | 文章
                  h3
                    | 抱紧这个 2021 年营销日历，出海营销逢节必旺！
          slide
            .slide-container
              .article-card(@click="openUri('https://mp.weixin.qq.com/s/TODv7HF8vC2k1GWwh2cYLQ')")
                img.article-image(src="@/assets/images/posts/post_7.png")
                .article-content
                  p.article-content-title
                    | 文章
                  h3
                    | 购物广告帐号急诊室！官方最佳做法助您排查问题、恢复帐号
      md-divider
      .container#analysis-section
        .flex-section
          img.section-image(src="@/assets/images/section-cover-analysis.png")
          .section-content
            h2.section-header.my-4
              | 自我诊断分析
            md-button.md-dense.md-yellow.md-raised.lg-btn(href="/assessment_tools/branding")
              | 品牌孵化诊断分析
            md-button.md-dense.md-yellow.md-raised.lg-btn(href="/assessment_tools/sites")
              | 建站诊断分析
      md-divider
      .container
        .flex-section.flex-reverse
          img.section-image(src="@/assets/images/section-cover-whitepaper.png")
          .section-content
            h2.section-header.my-4
              | 下载《2021 中国跨境电商发展报告》
            .section-description
              | 获取更多行业洞察，为企业在用户、产品、品牌等方面提供参考，助力企业实现品牌化转型，成为品牌大站。
            md-button.md-dense.md-green.md-raised.lg-btn(@click="openUri('/static/2021 中国跨境电商发展报告.pdf')")
              | 点击下载
      .footer
        p
          | 如果您也希望尝试谷歌广告解决方案拓展您的海外业务，现在即可致电谷歌专家。
          br.mobile
          | 新用户还可领取最高 400 元优惠券，助力轻松开启谷歌广告之旅。
          br
          | 欢迎拨打：400 - 819 - 1244
          br
          | 工作日：9:00 - 18:00
        md-divider
        img.footer-logo(src="@/assets/images/logo-36Kr-grey.png")
  .hidden(:class="{ 'share-mobile': isShare }", @click="() => { isShare = false; }")
    img.share-img(src="~@/assets/images/share.png")
  .shares
    .share-icon-container(@click="share")
      md-icon.share-icon share
      .hidden(:class="{ 'share-pc': isShare }", @click="() => { isShare = false; }")
        img(src="~@/assets/images/share-pc.png", width='150px')
    .share-icon-container(@click="like")
      md-icon.share-icon(:class="{ active: isActive }") favorite
    //.share-icon-container(@click="star")
    //  md-icon.share-icon(:class="{ star: isStar }") bookmarks
</template>

<script>
export default {
  name: "ThinkOfRetail",
  components: {},
  data() {
    return {
      isActive: false,
      isStar: false,
      isShare: false,
      isVideoToEnd: false,
      menuVisible: false,
    };
  },
  methods: {
    like() {
      this.$pushEvent("like", {});
      this.isActive = !this.isActive;
    },
    star() {
      this.isStar = !this.isStar;
    },
    share() {
      this.$pushEvent("share", {});
      this.isShare = !this.isShare;
    },
    openUri(uri) {
      this.$pushEvent("openUri", { url: uri }).then(() => {
        const ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf("safari") !== -1 && ua.indexOf("chrome") === -1) {
          window.location.assign(uri);
        } else {
          window.open(uri, "_blank");
        }
      });
    },
    videosBack() {
      this.isVideoToEnd = false;
      const ele = this.$refs.videos;
      ele.scrollLeft -= 500;
    },
    videosForward() {
      this.isVideoToEnd = true;
      const ele = this.$refs.videos;
      ele.scrollLeft += 500;
    },
  },
  mounted() {
    this.$pushEvent("visit", { route: "home" });
    if (window.screen.width > 1500) {
      const ele = this.$refs.videos;
      let pos = { top: 0, left: 0, x: 0, y: 0 };

      const mouseDownHandler = (e) => {
        // e.preventDefault();
        // ele.style.cursor = "grabbing";
        ele.style.userSelect = "none";

        pos = {
          left: ele.scrollLeft,
          top: ele.scrollTop,
          x: e.clientX,
          y: e.clientY,
        };

        document.addEventListener("mousemove", mouseMoveHandler);
        document.addEventListener("mouseup", mouseUpHandler);
      };

      const mouseMoveHandler = (e) => {
        const dx = e.clientX - pos.x;
        const dy = e.clientY - pos.y;

        ele.scrollTop = pos.top - dy;
        ele.scrollLeft = pos.left - dx;
      };

      const mouseUpHandler = () => {
        // ele.style.cursor = "grab";
        ele.style.removeProperty("user-select");

        document.removeEventListener("mousemove", mouseMoveHandler);
        document.removeEventListener("mouseup", mouseUpHandler);
      };

      ele.addEventListener("mousedown", mouseDownHandler);
    }
  },
};
</script>

<style lang="stylus" scoped>
img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

h2 {
  text-align: center;
}

h3 {
  font-size: 15px;
}

a {
  cursor: pointer;
}

.md-toolbar {
  min-height: 48px;
}

.pc {
  @media screen and (min-width: 1280px) {
    display: block;
  }
  display: none;
}

.mobile {
  @media screen and (min-width: 1280px) {
    display: none;
  }
  display: block;
}

.arrow-container {
  @media screen and (min-width: 1280px) {
    display: flex;
    justify-content center
    align-items center
    position: relative;
    height: 50px;
  }
  display: none;
}

.arrow {
  @media screen and (min-width: 1280px) {
    top: 10px;
    z-index: 9;
    display: block;
    cursor: pointer;
  }
}

.arrow-back {
  margin-right 10px;
}

.arrow-forward {
  margin-left 10px;
}

.arrow-icon {
  background-color #cccccc;
  width: 10px;
  height: 10px;
  border-radius 5px;
  &.active {
    background-color #000000;
  }
}

.share-img {
  width: 200px;
  position: absolute;
  right: 0;
  top: 0;
}

.hidden {
  display: none;
}

.share-pc {
  @media screen and (min-width: 1024px) {
    display: block;
    position: absolute;
    left: -150px;
    top: -90px;
  }
}

.share-mobile {
  @media screen and (max-width: 1024px) {
    display: block;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.3;
  }
}

.shares {
  position: fixed;
  background-color: #fff;
  right: 0;
  top: 50%;
  display: flex;
  flex-direction: column;
  border: 1px solid;
  border-radius: 10px 0 0 10px;

  @media screen and (max-width: 1280px) {
    top: 30%;
  }
}

.share-icon-container {
  margin: 10px;
  cursor: pointer;
}

.share-icon {
  color: #000 !important;
}

.active {
  color: #ff0000 !important;
}

.star {
  color: #ffcc00 !important;
}

.my-4 {
  margin-bottom: 20px;
}

.my-8 {
  margin-bottom: 40px;
}

.app {
  max-width: 100vw;
  max-height: 100vh;
  position: absolute;
}

.app-container {
  max-width: 100vw;
  max-height: 100vh;
}

.flex {
  display: flex;
}

.spacer {
  flex: 1;
}

.header-logo {
  height: 25px;
}

.padding-btn {
  padding: 5px 10px;
}

.container {
  @media screen and (min-width: 1280px) {
    width: 70%;
  }

  width: 100%;
  margin: 20px auto;
}

.cover-container {
  @media screen and (min-width: 1280px) {
    padding: 5vh 0;
  }
}

.cover {
  display: none;
  height 60vh;
  margin: auto;

  @media screen and (min-width: 1280px) {
    display: block;
  }
}

.cover-text {
  padding 0 20px;
  line-height: 1.8;
  text-align: left;
  @media screen and (min-width: 1280px) {
    padding 0;
    font-size: 20px;
  }
}

.cover-mobile {
  display: block;
  margin: 0 auto;
  padding 10px 30px;

  @media screen and (min-width: 1280px) {
    display: none;
  }
}

.md-content {
  padding: 0;
}

.videos-container {
  width: 100vw;
  display: flex;
  position: relative;

  @media screen and (min-width: 1280px) {
    display: block;
    overflow-x: scroll;
    cursor: grab;
  }

  overflow-x: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.videos-container::-webkit-scrollbar {
  display: none;
}

.videos {
  width: 90%;
  margin: 0 auto;

  @media screen and (min-width: 1280px) {
    width: 2200px; // 440px * 5
  }

  @media screen and (max-width: 1280px) {
    h3 {
      margin 0;
    }
  }
}

.video-card {
  display: block;
  margin: 20px auto;
  padding: 0 0 10px 0;
  width: 90%;
  cursor pointer;

  @media screen and (min-width: 1280px) {
    width: 400px;
    display: inline-block;
    margin: 20px;
  }

  border-radius: 32px 32px 32px 32px;

  h3 {
    font-weight normal;
    font-size 1.1rem;
    margin: 0.5rem 0;
    height: 40px;
  }

  .span-btn {
    display block
    margin 8px 0 0 -5px;
  }
}

.video-content-text {
  color: rgb(117, 117, 117);
  margin-bottom 5px;
}

.article-content-title {
  color: rgb(117, 117, 117);
}

.bg-grey {
  background-color: #f2f2f2;
}

.bg-white {
  background-color: #fff;
}

.md-card-media img {
  border-radius: 32px 32px 0 0;
}

.span-btn:hover {
  text-decoration: none;
}

.span-text {
  display: inline-block;
  line-height: 24px;
  vertical-align: middle;
}

.slide-container {
  @media screen and (min-width: 1280px) {
    width: 800px;
  }

  width: 90%;
  margin: 0 auto;
}

.article-card {
  display: flex;
  cursor: pointer;
  margin: 20px 0;
}

.article-image {
  @media screen and (min-width: 1024px) {
    width: 200px;
    height: calc(200px / 4 * 3);
    border-radius: 32px;
    margin: 0 30px 0 0;
  }

  width: 140px;
  height: calc(140px / 4 * 3);
  border-radius: 16px;
  margin: 0 15px 0 0;
}

.article-content {
  flex: 4;
}

.flex-section {
  display: flex;
  padding: 40px 0;

  @media screen and (min-width: 1280px) {
    flex-direction: row;
    padding: 60px 0;
    justify-content center;
    align-items center;
    max-width 1000px;
    margin: 0 auto;
  }

  flex-direction: column;
}

.section-description {
  margin: 0 0 10px 0;

  //@media screen and (min-width: 1280px) {
  //  padding: 0 110px;
  //}
}

.section-image {
  max-width: 90%;

  @media screen and (min-width: 1280px) {
    max-width: 50%;
  }

  width: auto;
  height: auto;
  justify-self: flex-start;
  align-self: flex-start;
  margin: 0 auto;
}

.section-content {
  @media screen and (min-width: 1280px) {
    width: 440px;
    padding: 0 40px;
  }

  padding: 0 10px;
  margin: auto 0;
}

.lg-btn {
  width: 220px;
  margin: 20px auto;
  display block;
}

.flex-reverse {
  @media screen and (min-width: 1280px) {
    flex-direction: row-reverse;
  }

  flex-direction: column;
}

.footer {
  background-color: #f5f5f5;
  padding: 20px;

  .footer-logo {
    margin: 20px 0 0 0;
    // margin: 20px auto 0 auto; 如果需要左右居中，使用此行
    height: 20px;
    display: block;
  }
}

.tabs {
  @media screen and (min-width: 1280px) {
    display: block;
  }
  display: none;
}
</style>
